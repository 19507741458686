import { observer } from 'mobx-react';

import styles from 'css/home.module.css';
import OESLogo from 'components/Icons/OESLogo';

const Home = () => {
  return (
    <div className={styles.bg}>
      <h2 className={styles.text}>Stream your events like a pro</h2>
      <p className={styles.text}>No more restreaming from a Twitch web feed!</p>
      <p className={styles.text}>
        With our easy to use cloud-based solution, you can host a high quality streaming event with
        ease - on demand, and at an affordable price.
      </p>
      <OESLogo />
      <p className={styles.footer}>Copyright © 2020 Online Event Services.</p>
    </div>
  );
};

export default observer(Home);
